/* Header */

.header-container {
  display: flex;
}

header {
  margin-bottom: 15px;
}

nav {
  padding: 5px;
  margin: 0;
  text-align: right;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  top: 0;
}

.nav-list {
  display: flex;
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}

a.link.link--nav {
  color: white !important;
  margin-left: 10px;
  text-decoration: none;
}

a.link.link--nav.active {
  text-decoration: underline;
}

a.nav-link.active {
  padding: 1px;
  text-decoration: underline;
}

a.nav-link {
  padding: 1px;
  color: white !important;
  font-size: 20px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.header-text {
  color: white;
  font-size: 20px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Content */

body {
  background-image: url('./assets/moody-clouds.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: black !important;
  font-family: "Bebas Neue", sans-serif !important;
  color: white !important;
  display: flex;
}

.home-intro {
  font-family: "Bebas Neue", sans-serif;
  font-size: 32px;
  line-height: 1.0;
  padding: 20px;
  /* position: fixed; */
  bottom: 10%;
  left: 10%;
  right: 30%;
  top: 70%;
}

.hi-im-chantel {
  font-size: 60px;
}

/* Projects */

.projects-body {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
}

.projects-container {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  transition: transform 0.2s linear;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-card {
  width: 370px;
  margin: 10px;
}

.project__description {
  margin-top: 1em;
  font-size: 20px;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
}

.project .link--icon {
  margin-left: 0.5em;
}

.link--icon {
  color: white !important;
}

.project-image {
  filter: grayscale(80%);
}

.project-image:hover {
  filter: brightness(100%);
}
/* Header Styling */
.title {
  text-align: center;
}

/* Skills */

.skills__list {
  max-width: 450px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
  color: #fff !important;
}

/* About Me */

.about {
  flex-direction: column;
  padding: 1em;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .about {
    align-items: flex-start;
    margin-top: 1em;
  }
}

/* Contact Styling */

.form-container {
  max-width: 600px;
  padding: 1em;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form-input {
  width: 100%;
  padding: 15px 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea.form-input {
  height: 150px; /* Set the height of the textarea */
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 5px 0 5px 0;
}

.row-item-name {
  margin-right: 5px;
}

.row-item-email {
  margin-left: 5px;
}

input[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #1a1b1c;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

input[type="submit"]:hover {
  background-color: black;
}

/* Footer */
footer {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 5px;
  color: white;
  display: flex;
  justify-content: center;
}